<template>
    <StatisticGeneral></StatisticGeneral>
</template>

<script>
import StatisticGeneral from "@/components/statistic/general";

export default {
    name: "dashboard",
    components: {
        StatisticGeneral
    },
    mounted(){
        this.setCurrentPageTitle(this.$t('menu.dashboard'));
    }
}
</script>